<template>
    <!-- <JDYL v-if="vrUrl" :vrUrl="vrUrl"></JDYL> -->
    <!-- <div class="BigNavigation">
        <div class="shouzhi" @click="Back()">
            <i style="position: absolute;z-index: 200;display: flex;align-items: center;justify-content: center;"><img src="../../assets/CulturalHomePage/PomePage.png" alt="" /></i>
            <div class="back"></div>
            <p class="item">首</p>
            <div class="Navigation "></div>
        </div>
    </div> -->
    <div class="BigBox" :class="{
                BgJG: ConData.scenicName == '靖港',
                BgTG: ConData.scenicName == '铜官',
                BgSTS: ConData.scenicName == '书堂山',
                BgXK: ConData.scenicName == '新康',
                BgQK: ConData.scenicName == '乔口',
            }">
        <div class="Head">
            <div class="Title">
                <!-- src="../../assets/Scenicspace/new/icon_JGGZ.png" -->
                <img :src="icon">
                <span>云游{{ConData.scenicName}}</span>
            </div>
            <div class="Back" @click="Back">
                <img src="../../assets/SpecialScenic/new/back.png" alt="" />
            </div>
        </div>
        <div class="Map">
            <!-- 靖港 -->
            <div class="JGMapImg MapImg" v-if="ConData.scenicName == '靖港'">
                <img src="../../assets/Scenicspace/new/mapJG.png" class="mapJG map">
                <!-- 老街1 -->
                <img src="../../assets/Scenicspace/new/icon_address.gif" class="Address1 Address" @click="goJG(1)">
                <!-- 风雅广场 -->
                <img src="../../assets/Scenicspace/new/icon_address.gif" class="Address2 Address" @click="goJG(2)">
                <!-- 观音寺 -->
                <img src="../../assets/Scenicspace/new/icon_address.gif" class="Address3 Address" @click="goJG(3)">
                <!-- 老街2 -->
                <img src="../../assets/Scenicspace/new/icon_address.gif" class="Address4 Address" @click="goJG(4)">
                <!-- 老街3 -->
                <img src="../../assets/Scenicspace/new/icon_address.gif" class="Address5 Address" @click="goJG(5)">
                <!-- 靖港古镇 -->
                <img src="../../assets/Scenicspace/new/icon_address.gif" class="Address6 Address" @click="goJG(6)">
                <!-- 曾国藩水营 -->
                <img src="../../assets/Scenicspace/new/icon_address.gif" class="Address7 Address" @click="goJG(7)">
            </div>
            <!-- 铜官 -->
            <div class="TGMapImg MapImg" v-if="ConData.scenicName == '铜官'">
                <img src="../../assets/Scenicspace/new/mapTG.png" class="mapTG map">
                <!-- 老街1 -->
                <img src="../../assets/Scenicspace/new/icon_address.gif" class="Address1 Address" @click="goTG(1)">
                <!-- 老街2 -->
                <img src="../../assets/Scenicspace/new/icon_address.gif" class="Address2 Address" @click="goTG(2)">
            </div>
            <!-- 新康 -->
            <div class="XKMapImg MapImg" v-if="ConData.scenicName == '新康'">
                <img src="../../assets/Scenicspace/new/mapXK.png" class="mapXK map">
                <!-- 新康剧院 -->
                <img src="../../assets/Scenicspace/new/icon_address.gif" class="Address1 Address" @click="goXK(1)">
                <!-- 洪山古寺 -->
                <img src="../../assets/Scenicspace/new/icon_address.gif" class="Address2 Address" @click="goXK(2)">
            </div>
            <!-- 书堂山 -->
            <div class="STSMapImg MapImg" v-if="ConData.scenicName == '书堂山'">
                <img src="../../assets/Scenicspace/new/mapSTS.png" class="mapSTS map">
                <!-- 书堂山 -->
                <img src="../../assets/Scenicspace/new/icon_address.gif" class="Address1 Address" @click="goSTS(1)">
                <!-- 书香门 -->
                <img src="../../assets/Scenicspace/new/icon_address.gif" class="Address2 Address" @click="goSTS(2)">
            </div>
            <!-- 乔口 -->
            <div class="QKMapImg MapImg" v-if="ConData.scenicName == '乔口'">
                <img src="../../assets/Scenicspace/new/mapQK.png" class="mapQK map">
                <!-- 大码头 -->
                <img src="../../assets/Scenicspace/new/icon_address.gif" class="Address1 Address" @click="goQK(1)">
                <!-- 百寿街 -->
                <img src="../../assets/Scenicspace/new/icon_address.gif" class="Address2 Address" @click="goQK(2)">
                <!-- 杜甫码头 -->
                <img src="../../assets/Scenicspace/new/icon_address.gif" class="Address3 Address" @click="goQK(3)">
                <!-- 三贤祠 -->
                <img src="../../assets/Scenicspace/new/icon_address.gif" class="Address4 Address" @click="goQK(4)">
                <!-- 万寿宫 -->
                <img src="../../assets/Scenicspace/new/icon_address.gif" class="Address5 Address" @click="goQK(5)">

            </div>
        </div>
        <div class="Menu">
            <div class="Box" @click="showSummary=true">
                <img :src="icon">
                <div class="name">{{ConData.scenicName}}概览</div>
            </div>
            <div class="Box" @click="showYunZhan=true">
                <img src="../../assets/Scenicspace/new/icon_WHYZ.png">
                <div class="name">文化云展</div>
            </div>
        </div>
        <!-- 概览弹窗 -->
        <div class="summary" v-if="showSummary">
            <div class="Close" @click="showSummary=false">
                <img src="../../assets/Scenicspace/new/close.png">
            </div>
            <div class="Title">{{ConData.scenicName}}</div>
            <div class="Content">{{ ConData.summary }}</div>
        </div>
        <!-- 概览弹窗 -->
        <div class="YunZhan" v-if="showYunZhan">
            <div class="Close" @click="showYunZhan=false">
                <img src="../../assets/Scenicspace/new/close.png">
            </div>
            <div class="Title">文化</div>
            <div class="MenuBox" @click="goFy">
                <img src="../../assets/Scenicspace/new/FeiYi.png">
                <div class="name">非遗文化展</div>
            </div>
            <div class="MenuBox" @click="goHs">
                <img src="../../assets/Scenicspace/new/HongSe.png">
                <div class="name">红色文化展</div>
            </div>
            <div class="MenuBox" @click="goLs">
                <img src="../../assets/Scenicspace/new/LiShi.png">
                <div class="name">历史文化展</div>
            </div>
        </div>
        <TiShiBox ref="childRef"></TiShiBox>
    </div>
</template>
<script>
import TiShiBox from '../Public/TiShiBox.vue'
import JDYL from './JDYL.vue'
// import mixin from './components/mixin.js'
import { ElLoading } from 'element-plus'


export default {
    components: { JDYL, TiShiBox },
    data () {
        return {
            id: 0,
            idx: 0,
            vrUrl: '',
            ConData: {},
            showSummary: false,
            showYunZhan: false,
            icon: '',
            iconArr: [
                require('../../assets/Scenicspace/new/icon_JGGZ.png'),
                require('../../assets/Scenicspace/new/icon_TGGZ.png'),
                require('../../assets/Scenicspace/new/icon_STS.png'),
                require('../../assets/Scenicspace/new/icon_XKGZ.png'),
                require('../../assets/Scenicspace/new/icon_QKGZ.png'),
            ]
        }
    },
    mounted () {
        const loading = ElLoading.service({
            lock: true,
            text: '加载中...',
            background: 'rgba(0, 0, 0, 0.8)',
        })
        setTimeout(() => {
            loading.close()
        }, 1000)
        this.id = this.$route.query.scenicId
        this.idx = +this.id - 1
        this.getData()
    },
    methods: {
        goFy () {
            if (this.ConData.scenicName == '靖港') window.open('https://roma.720yun.com/vr/c20122758bcee6c1', '_blank');
            if (this.ConData.scenicName == '铜官') window.open('https://roma.720yun.com/vr/44b66e529740fac7', '_blank');
            if (this.ConData.scenicName == '书堂山') window.open('https://roma.720yun.com/vr/8fd6ee40c350b4d8', '_blank');
            if (this.ConData.scenicName == '新康') window.open('https://roma.720yun.com/vr/0b25ccaeb1c09028', '_blank');
            if (this.ConData.scenicName == '乔口') window.open('https://roma.720yun.com/vr/089a7532c304d4e8', '_blank');
        },
        goHs () {
            if (this.ConData.scenicName == '靖港') window.open('https://roma.720yun.com/vr/c950b8afc5b17818', '_blank');
            if (this.ConData.scenicName == '铜官') window.open('https://roma.720yun.com/vr/378a9651c7443a94', '_blank');
            if (this.ConData.scenicName == '书堂山') window.open('https://roma.720yun.com/vr/94d49380bd643edf', '_blank');
            if (this.ConData.scenicName == '新康') this.$refs.childRef.ChuFa()
            if (this.ConData.scenicName == '乔口') window.open('https://roma.720yun.com/vr/c843a0156fd12191', '_blank');
        },
        goLs () {
            if (this.ConData.scenicName == '靖港') window.open('https://roma.720yun.com/vr/d0a760dd4ea08177', '_blank');
            if (this.ConData.scenicName == '铜官') window.open('https://roma.720yun.com/vr/b5be768386913664', '_blank');
            if (this.ConData.scenicName == '书堂山') window.open('https://roma.720yun.com/vr/44231d8ea1f97d97', '_blank');
            if (this.ConData.scenicName == '新康') this.$refs.childRef.ChuFa()
            if (this.ConData.scenicName == '乔口') window.open('https://roma.720yun.com/vr/61c4cac1500172cb', '_blank');
        },
        // 靖港
        goJG (idx) {
            if (idx == 1) window.open('https://www.720yun.com/vr/d19jz5snen6', '_blank');//老街1
            if (idx == 2) window.open('https://www.720yun.com/vr/eb7jz5snvf0', '_blank');//风雅广场
            if (idx == 3) window.open('https://www.720yun.com/vr/c0bjz5snvu7', '_blank');//观音寺
            if (idx == 4) window.open('https://www.720yun.com/vr/8dbjz5snek3', '_blank');//老街2
            if (idx == 5) window.open('https://www.720yun.com/vr/1fbjz5sntn5', '_blank');//老街3
            if (idx == 6) window.open('https://www.720yun.com/vr/aa826xi8ces', '_blank');//靖港牌坊
            if (idx == 7) window.open('https://www.720yun.com/vr/4e4jz5sntk1', '_blank');//曾国藩水营
        },
        // 新康 
        goXK (idx) {
            if (idx == 1) window.open('https://www.720yun.com/vr/6f3jzpumkf3', '_blank');
            if (idx == 2) window.open('https://www.720yun.com/vr/e0b2dxikxeg', '_blank'); //洪山古寺
        },
        // 铜官  
        goTG (idx) {
            if (idx == 2) window.open('https://www.720yun.com/vr/a3025xikxen', '_blank'); //老街1
            if (idx == 1) window.open('https://www.720yun.com/vr/557jzpun5w6', '_blank'); //老街2
        },
        // 书堂山
        goSTS (idx) {
            if (idx == 1) window.open('https://www.720yun.com/vr/78521xikxlr', '_blank'); //书堂山
            if (idx == 2) window.open('https://www.720yun.com/vr/efdjzpuwrm4', '_blank'); //书香门
        },
        // 乔口
        goQK (idx) {
            if (idx == 1) window.open('https://www.720yun.com/vr/47120xikxl6', '_blank'); //大码头
            if (idx == 2) window.open('https://www.720yun.com/vr/516jz5svrv1', '_blank'); //百寿街
            if (idx == 3) window.open('https://www.720yun.com/vr/9b8jz5svum2', '_blank'); //杜甫码头
            if (idx == 4) window.open('https://www.720yun.com/vr/eddjz5svuv8', '_blank'); //三贤祠
            if (idx == 5) window.open('https://www.720yun.com/vr/deajz5suOf7', '_blank'); //万寿宫
        },
        Back () {
            this.$router.push({ path: "/" });
        },
        getData () {
            var axios = require('axios')
            var config = {
                method: 'get',
                url: this.$Schttp + '/vtp/app/scenic/detail/' + this.id,
            }
            axios(config).then((res) => {
                this.vrUrl = res.data.data.vrUrl
                this.ConData = res.data.data
                if (this.ConData.scenicName == '靖港') this.icon = this.iconArr[0]
                if (this.ConData.scenicName == '铜官') this.icon = this.iconArr[1]
                if (this.ConData.scenicName == '书堂山') this.icon = this.iconArr[2]
                if (this.ConData.scenicName == '新康') this.icon = this.iconArr[3]
                if (this.ConData.scenicName == '乔口') this.icon = this.iconArr[4]
            }).catch(function (error) { })
        },
    },
    // mixins: [mixin]
}
</script>
<style lang="less" scoped>
@font-face {
    font-family: 'STXINGKA';
    src: url('../../ZiTi/STXINGKA.TTF');
}
.BgJG {
    background: url(../../assets/Scenicspace/new/BGJG.jpg) no-repeat;
    background-size: 100% 100%;
}
.BgXK {
    background: url(../../assets/Scenicspace/new/BGXK.jpg) no-repeat;
    background-size: 100% 100%;
}
.BgTG {
    background: url(../../assets/Scenicspace/new/BGTG.jpg) no-repeat;
    background-size: 100% 100%;
}
.BgSTS {
    background: url(../../assets/Scenicspace/new/BGSTS.jpg) no-repeat;
    background-size: 100% 100%;
}
.BgQK {
    background: url(../../assets/Scenicspace/new/BGQK.jpg) no-repeat;
    background-size: 100% 100%;
}
.BigBox {
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    // background: url(../../assets/Scenicspace/new/BGTG.jpg) no-repeat;
    // background-size: 100% 100%;
    padding: 0 10%;
    font-family: 'STXINGKA';
    box-sizing: border-box;
    .Map {
        width: 80%;
        position: fixed;
        display: flex;
        flex-direction: column;
        align-items: center;
        .MapImg {
            width: 120vh;
            height: 85vh;
            position: relative;
            .map {
                width: 100%;
                height: 100%;
            }
            .Address {
                width: 5vh;
                position: absolute;
                cursor: pointer;
            }
        }
        .JGMapImg {
            .Address1 {
                top: 40vh;
                left: 4vh;
            }
            .Address2 {
                top: 37vh;
                left: 18.7vh;
            }
            .Address3 {
                top: 30.5vh;
                left: 29.2vh;
            }
            .Address4 {
                top: 32vh;
                left: 48.7vh;
            }
            .Address5 {
                top: 37.5vh;
                left: 83vh;
            }
            .Address6 {
                top: 46vh;
                left: 69vh;
            }
            .Address7 {
                top: 46vh;
                left: 40vh;
            }
        }
        .STSMapImg {
            .Address1 {
                top: 32vh;
                left: 36.8vh;
            }
            .Address2 {
                top: 27vh;
                left: 73vh;
            }
        }
        .TGMapImg {
            .Address1 {
                top: 23vh;
                left: 23vh;
            }
            .Address2 {
                top: 35vh;
                left: 47.5vh;
            }
        }
        .XKMapImg {
            .Address1 {
                top: 38vh;
                left: 41vh;
            }
            .Address2 {
                top: 29vh;
                left: 66.5vh;
            }
        }
        .XKMapImg {
            .Address1 {
                top: 38vh;
                left: 41vh;
            }
            .Address2 {
                top: 29vh;
                left: 66.5vh;
            }
        }
        .QKMapImg {
            .Address1 {
                top: 42vh;
                left: 25.5vh;
            }
            .Address2 {
                top: 39.5vh;
                left: 39.5vh;
            }
            .Address3 {
                top: 38vh;
                left: 70vh;
            }
            .Address4 {
                top: 45vh;
                left: 75vh;
            }
            .Address5 {
                top: 48vh;
                left: 75.5vh;
            }
        }
    }
    .Head {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 10vh;
        align-items: flex-end;
        .Back {
            width: 7.5vh;
            height: 7vh;
            z-index: 999;
            cursor: pointer;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .Title {
            color: #1861ee;
            align-items: end;
            display: flex;
            font-size: 5vh;
            img {
                width: 10vh;
                margin-right: 2vh;
            }
        }
    }
    .Menu {
        color: #94704c;
        margin-top: 8vh;
        .Box {
            text-align: center;
            width: 12vh;
            position: relative;
            margin-top: 3vh;
            cursor: pointer;
            img {
                width: 9vh;
                z-index: 0;
            }
            .name {
                width: 12vh;
                border-radius: 4.896px;
                border: 0.571px solid #edd5bd;
                background: #fcf0e3;
                font-size: 2vh;
                position: absolute;
                top: 5vh;
            }
        }
    }
    .summary,
    .YunZhan {
        width: 60%;
        height: 70%;
        border-radius: 10px;
        background: #fefaf6;
        box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1);
        position: fixed;
        left: 20%;
        top: 15%;
        color: #94704c;
        padding: 3vh 4vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        .Title {
            text-align: center;
            font-size: 3vh;
            padding-bottom: 1vh;
            margin-bottom: 1vh;
            background: url(../../assets/Scenicspace/new/bottomLine.png)
                no-repeat bottom;
        }
        .Close {
            width: 3vh;
            height: 3vh;
            position: absolute;
            right: -2vh;
            top: -2vh;
            cursor: pointer;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .Content {
            font-family: Microsoft YaHei;
            text-indent: 2rem;
            line-height: 2rem;
            font-size: 1.6vh;
            overflow-y: scroll;
        }
    }
    .YunZhan {
        width: 32%;
        height: 40%;
        left: 35%;
        top: 20%;
        .Title {
            width: 100%;
            background: none;
            border-bottom: 1px solid #edd5bd;
        }
        .MenuBox {
            align-self: flex-start;
            display: flex;
            align-items: center;
            margin-top: 2%;
            padding: 2vh 2vh;
            width: 100%;
            box-sizing: border-box;
            img {
                margin-right: 2vh;
                width: 8vh;
            }
        }
        .MenuBox:hover {
            border-radius: 10px;
            background: #f5f5f5;
        }
    }

    @remvw: 1920 /100vw !important;

    ::-webkit-scrollbar {
        width: 7 / @remvw;
        /*对垂直流动条有效*/
        height: 7 / @remvw;
        /*对水平流动条有效*/
    }

    /*定义外层滚动槽*/
    ::-webkit-scrollbar-track-piece {
        border-radius: 6 / @remvw;
    }

    /*定义内层 滑块颜色、内阴影及圆角*/
    ::-webkit-scrollbar-thumb {
        border-radius: 6 / @remvw;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: #8b8b8b;
        opacity: 0.3;
    }

    /*定义两端按钮的样式*/
    ::-webkit-scrollbar-button {
        display: none;
    }

    /*定义边角样式*/
    ::-webkit-scrollbar-corner {
        display: none;
    }
}
</style>